:root {
    --green: #005030;
    --dark-green: #004020;
    --light-green: #88ffcc;
}

html,
body,
.Display {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    image-rendering: -webkit-optimize-contrast;
    background: white;
    color: black;
}

body * {
    box-sizing: border-box;
}

div {
    position: relative;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.App > .Page {
    width: 100%;
    height: calc(100% - 60px);
}

.FlexRow {
    display: flex;
    flex-direction: row;
}

.FlexColumn {
    display: flex;
    flex-direction: column;
}

.FlexSpace {
    flex: 1 1 auto;
}

.Clickable {
    cursor: pointer;
}

.HoverHighlight {
    cursor: pointer;
}

.HoverHighlight:hover {
    filter: brightness(110%);
}

.HoverOpacity {
    cursor: pointer;
}

.HoverOpacity:hover {
    opacity: 0.8;
}

.HoverBold {
    cursor: pointer;
    padding: 0 1.5px;
}

.HoverBold:hover {
    font-weight: bold;
    padding: 0 0;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.spinner-grow {
    background-color: var(--light-blue);
    animation: spinner-grow 1.2s linear infinite;
}

.btn {
    border-radius: 2px;
}

.btn-primary {
    background: var(--green);
    border-color: var(--green);
}

.btn-primary:hover {
    background: var(--dark-green);
    border-color: var(--dark-green);
}

.btn-primary:not(:disabled):not(.disabled):active {
    background: var(--dark-green);
    border-color: var(--dark-green);
}

.btn-primary:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 1px 1px rgba(231, 84, 51, 0.5);
}

.btn-primary:disabled {
    background: var(--dark-green);
    border-color: var(--dark-green);
}

.btn-secondary:focus {
    box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 1px 1px rgba(108, 117, 125, 0.5);
}

.dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    padding: 0;
    box-shadow: rgba(50, 50, 50, 0.3) 2px 2px 5px 0px;
    opacity: 1 !important;
    pointer-events: unset !important;
    z-index: 1100;
}

.dropdown-menu-right {
    left: unset !important;
    right: 0 !important;
}

.dropdown-item {
    display: flex;
    flex-direction: row;
    padding: 1px 10px 4px;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--green);
}

.form-control:focus {
    border-color: rgb(206, 212, 218);
    box-shadow: none;
}

.custom-file-input-parent * {
    cursor: pointer !important;
}

.link {
    color: #007bff;
    cursor: pointer;
}

.link:hover {
    color: #0056b3;
    text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.NoSelect {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

@media print {
    @page {
        size: landscape;
    }
    .HidePrint {
        display: none !important;
    }
}

.AppToast {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translateX(-50%);
    background: #eee;
    padding: 2px 12px;
    border-radius: 12px;
    z-index: 1200;
}

.AppScroll {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: var(--green) var(--light-green);
}
.AppScroll::-webkit-scrollbar {
    width: 8px;
}
.AppScroll::-webkit-scrollbar-track {
    background: var(--light-green);
}
.AppScroll::-webkit-scrollbar-thumb {
    background: var(--green);
    border: 1px solid var(--light-green);
}
